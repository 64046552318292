import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SubHeading from "../SubHeading/SubHeading";
import { db } from "../../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  addDoc,
  Timestamp,
} from "firebase/firestore";
import "./Newsletter.css";

const Newsletter = () => {
  const [email, setEmail] = useState("");

  const checkAndAddEmail = async () => {
    const newsletterRef = collection(db, "newsletter");
    const q = query(newsletterRef, where("email", "==", email));

    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      toast.info("Thank you, you have already subscribed!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      await addDoc(newsletterRef, {
        email: email,
        timestamp: Timestamp.fromDate(new Date()),
      });
      toast.success("Thank you for subscribing!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setEmail(""); // Clear the input after successful subscription
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email) {
      try {
        await checkAndAddEmail();
      } catch (error) {
        toast.error("Error subscribing: " + error.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      toast.warn("Please enter a valid email address", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <div className="app__newsletter">
      <div className="app__newsletter-heading">
        <SubHeading title="Newsletter" />
        <h1 className="headtext__cormorant" style={{ color: "palegoldenrod" }}>
          Subscribe to our newsletter
        </h1>
        <p className="p__opensans" style={{ color: "white" }}>
          And never miss out latest updates
        </p>
      </div>
      <form
        className="app__newsletter-input flex__center flex-column flex-md-row"
        onSubmit={handleSubmit}
      >
        <input
          type="email"
          placeholder="Enter your Email address"
          className="me-md-4 mb-4 mb-md-0"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button type="submit" className="custom__button">
          Subscribe
        </button>
      </form>
      <ToastContainer />
    </div>
  );
};

export default Newsletter;
