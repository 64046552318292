import React, { useState, useRef } from "react";
import { useSwipeable } from "react-swipeable";
import menuImage1 from "../../assets/menuOne.png";
import menuImage2 from "../../assets/menuTwo.png";
import "./RegularMenu.css";

const RegularMenu = () => {
  const [currentMenu, setCurrentMenu] = useState(0);
  const modalRef = useRef(null); // Reference to the modal container

  const showNextMenu = () => {
    setCurrentMenu((prevMenu) => (prevMenu === 0 ? 1 : 0));
  };

  const showPreviousMenu = () => {
    setCurrentMenu((prevMenu) => (prevMenu === 0 ? 1 : 0));
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => showNextMenu(),
    onSwipedRight: () => showPreviousMenu(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const menuImages = [menuImage1, menuImage2];
  const currentImage = menuImages[currentMenu];

  // Function to close the modal by clicking outside the image
  const handleCloseModal = (e) => {
    if (modalRef.current && e.target === modalRef.current) {
      const modalElement = document.getElementById("RegularMenu");
      // Bootstrap 5 method to programmatically hide a modal
      const bootstrapModal = bootstrap.Modal.getInstance(modalElement);
      bootstrapModal.hide();
    }
  };

  return (
    <div
      className="modal fade"
      id="RegularMenu"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modalTitleId"
      aria-hidden="true"
      style={{ backgroundColor: "rgba(0,0,0,0.4)" }}
    >
      <div className="modal-dialog modal-sm modal-fullscreen" role="document">
        <div
          className="modal-content"
          style={{ backgroundColor: "transparent", border: "none" }}
          {...handlers}
          onClick={handleCloseModal}
          ref={modalRef} // Assigning ref to the modal content for reference
        >
          <div className="modal-header border-0 d-flex justify-content-end">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              style={{
                filter: "invert(1)",
                backgroundColor: "transparent",
                borderColor: "transparent",
                position: "absolute",
                top: 10,
              }}
            />
          </div>
          <div className="modal-body d-flex justify-content-center align-items-center">
            <button
              className="btn modal-nav-button"
              onClick={showPreviousMenu}
              style={{ position: "absolute", left: 20 }}
            >
              &lt;
            </button>
            <img
              src={currentImage}
              alt="Menu"
              style={{
                maxWidth: "100vw",
                maxHeight: "100vh",
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
            <button
              className="btn modal-nav-button"
              onClick={showNextMenu}
              style={{ position: "absolute", right: 20 }}
            >
              &gt;
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegularMenu;
