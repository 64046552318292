import React from "react";
import SubHeading from "../../components/SubHeading/SubHeading";
import "./Header.css";
import { images } from "../../constants";

const Header = () => (
  <div
    className="app__header app__wrapper section__padding d-flex flex-column flex-md-row"
    id="home"
  >
    <div className="app__wrapper_info">
      <SubHeading title="Chase a new flavor" />
      <h1 className="app__header-h1">A Place to Savor Every Moment</h1>
      <p className="p__opensans my-2">
        Whether you’re craving savory snacks or sweet treats, every bite at Cafe
        Noir is a delicious discovery. Join us for a meal where the only missing
        ingredient is you!!
      </p>
      <button
        type="button"
        className="custom__button"
        data-bs-toggle="modal"
        data-bs-target="#RegularMenu"
      >
        Explore Menu
      </button>
    </div>

    <div className="app__wrapper_img">
      <img src={images.welcome} alt="header img" loading="lazy" />
    </div>
  </div>
);

export default Header;
