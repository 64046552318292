// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDsC716hl1adbdDQZD9s5O-G8nVnu2RwXE",
  authDomain: "cafenoir-453b8.firebaseapp.com",
  projectId: "cafenoir-453b8",
  storageBucket: "cafenoir-453b8.appspot.com",
  messagingSenderId: "953154187136",
  appId: "1:953154187136:web:d74e245df6cde8de605b65",
  measurementId: "G-5KRFW8GYC5",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export default app;
