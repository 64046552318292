import images from "./images";

const wines = [
  {
    title: "Cappucino",
    price: "R50",
    tags: "Espresso | Frothed Milk",
  },
  {
    title: "Coffee",
    price: "R50",
    tags: "Brewed | Hot",
  },
  {
    title: "Tea",
    price: "R45",
    tags: "Brewed | Leaf",
  },
];

const cocktails = [
  {
    title: "Soda",
    price: "R30",
    tags: "Carbonated | Refreshing",
  },
  {
    title: "Juice",
    price: "R45",
    tags: "Freshly Squeezed | Natural",
  },
  {
    title: "Water",
    price: "R30",
    tags: "Still | Mineral",
  },
];

const awards = [
  {
    imgUrl: images.award02,
    title: "Bib Gourmond",
    subtitle: "Lorem ipsum dolor sit amet, consectetur.",
  },
  {
    imgUrl: images.award01,
    title: "Rising Star",
    subtitle: "Lorem ipsum dolor sit amet, consectetur.",
  },
  {
    imgUrl: images.award05,
    title: "AA Hospitality",
    subtitle: "Lorem ipsum dolor sit amet, consectetur.",
  },
  {
    imgUrl: images.award03,
    title: "Outstanding Chef",
    subtitle: "Lorem ipsum dolor sit amet, consectetur.",
  },
];

export default { wines, cocktails, awards };
