import React from "react";
import { FiFacebook, FiTwitter, FiInstagram } from "react-icons/fi";
// import SubHeading from '../../components';
import { FooterOverlay, Newsletter } from "../../components";
import { images } from "../../constants";
import "../../components/Navbar/Navbar.css";
import "./Footer.css";

const Footer = () => (
  <div className="app__footer section__padding pb-0">
    <FooterOverlay />
    <Newsletter />
    <div className="app__footer-links flex-column flex-md-row mt-5 px-3">
      <div className="app__footer-links_contact">
        <h1 className="app__footer-headtext">Contact Us</h1>
        <p className="p__opensans" style={{ color: "#cca541" }}>
          <a
            href="https://www.google.com/maps/search/?api=1&query=Café+Noir+Shop 1,+North+Piazza,+Illovo,+Sandton"
            target="_blank"
          >
            32 Fricker Road, Illovo, Sandton, Gauteng 2196
          </a>
        </p>

        <p className="p__opensans">
          <a href="tel:+27717572547">+27 (71) 757 - 2547</a>
        </p>
        <p className="p__opensans">
          <a href="tel:+27768257355">+27 (76) 825 - 7355</a>
        </p>
      </div>

      <div className="app__footer-links_logo">
        <h1
          className="app__navbar--logo d-flex justify-content-start align-items-center fw-bold m-0"
          style={{ marginBottom: "30px", fontFamily: "Dream Avenue" }}
        >
          Café Noir
        </h1>
        <div style={{ height: "40px" }} />
        <img src={images.spoon} alt="spoon" className="mt-3" loading="lazy" />
        <div className="app__footer-links_icons mt-1">
          <a href="https://www.instagram.com/cafenoir.sandton/">
            <FiInstagram />
          </a>
        </div>
      </div>

      <div className="app__footer-links_work">
        <h1 className="app__footer-headtext">Working hours</h1>
        <p className="p__opensans">Monday - Friday: </p>
        <p className="p__opensans">07:30 am - 05:30 pm</p>
        <p className="p__opensans">Saturday</p>
        <p className="p__opensans">08:30 am - 03:00 pm</p>
      </div>
    </div>
    <div className="footer__copyright">
      <p className="p__opensans">2024 Cafe Noir. All rights reserved</p>
    </div>
  </div>
);

export default Footer;
